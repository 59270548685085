<template>
  <div class="d-flex w-100 p-3 align-items-center">
    <img 
      :src="require('@/assets/img/HPlogo-light-XL.svg')"
      class="mw-50 pl-3 logo"
      alt="HP logo"
    >
    <div>
        <p id="logo-text" class="mb-0 ml-2 printer-name">{{textsVariables.name}}</p>
    </div>
    <div
      v-click-outside="onClickOutside"
      class="desktop-selector ml-auto"
    >
      <button
        class="language-button position-relative"
        @click="showLanguagePanel = !showLanguagePanel"
      >
      {{$i18n.messages[$i18n.locale].language_name}}
      <img v-show="!showLanguagePanel" :src="require('@/assets/img/arrowdown.svg')" >
      <img v-show="showLanguagePanel" :src="require('@/assets/img/arrowup.svg')" >
      </button>
      <div
        v-show="showLanguagePanel"
        class="position-relative bg-white language-menu-desktop mr-2 hide-mobile border"
      >
        <ul class="my-2 p-0">
          <li
            v-for="(language, index) in $i18n.availableLocales"
            :key="'language_' + index"
            class="d-flex px-2 py-1 align-items-center justify-content-center"
            :class="{
              'language-selected': $i18n.locale == language,
              'language-item': index == hoveredItem && $i18n.locale != language,
              'language-font-white': $i18n.locale == language
            }"
            @click="changeLocale(language)"
            @mouseover="hoveredItem = index"
            @mouseout="hoveredItem = null"
          >
            {{ $i18n.messages[language].language_name }}
          </li>
        </ul>
      </div>
    </div>
    <div
      v-show="showLanguagePanel"
      class="position-absolute bg-white h-100 w-100 language-menu hide-desktop"
    >
      <div class="d-flex flex-row-reverse w-100 p-3 border-bottom">
        <div
          class="language-square"
          @click="showLanguagePanel = !showLanguagePanel"
        >
          ✕
        </div>
      </div>
      <ul class="my-2 p-0">
          <li
            v-for="(language, index) in $i18n.availableLocales"
            :key="'language_' + index"
            class="d-flex px-2 py-2 align-items-center justify-content-center"
            :class="{
              'language-selected': $i18n.locale == language,
              'language-item': index == hoveredItem && $i18n.locale != language,
              'language-font-white': $i18n.locale == language
            }"
            @click="changeLocale(language)"
            @mouseover="hoveredItem = index"
            @mouseout="hoveredItem = null"
            style="font-family: Forma-Regular;"
          >
            {{ $i18n.messages[language].language_name }}
          </li>
        </ul>
    </div>
  </div>
</template>

<script lang="ts">
import {mapGetters, mapMutations} from 'vuex'
import fitty from 'fitty';

export default {
    data() {
        return {
            showLanguagePanel: false,
            hoveredItem: null
        };
    },
    mounted() {
        fitty('#logo-text')
    },
    computed: {
        ...mapGetters([ 'languageLocale', 'printer', 'printer_data' ]),
        textsVariables: function() {
            return this.printer_data(this)
        }
    },
    methods: {
        ...mapMutations([ 'updateLanguageLocale' ]),
        changeLocale(lang) {
            this.$i18n.locale = lang;
            this.updateLanguageLocale(lang);
        },
        onClickOutside() {
            if (
                this.showLanguagePanel &&
        window.matchMedia("only screen and (min-width: 992px)").matches
            )
                this.showLanguagePanel = false;
        }
    }
};
</script>

<style lang="scss" scoped>
button {
  padding: 5px;
  margin: 1px;
}



.language-menu {
  right: 0;
  top: 0;
  z-index: 999;
}

.language-button {
  border: none;
  z-index: 3;
  background-color: transparent;
  font-family: Arial;
  color: $hp-medium-gray;
  font-size: 15px;
}

.language-menu-desktop {
  top: 1rem;
  background: #ffffff;
  border-radius: 0.3rem;
  width: 8rem;
  font-family: Forma-Regular;
  color: $hp-medium-gray;
  font-size: 15px;
}

.language-menu-desktop:before,
.language-menu-desktop:after {
  content: "";
  display: block;
  position: absolute;
  bottom: 99.5%;
  width: 0;
  height: 0;
  z-index: 1;
  left: 50%;
  left: -webkit-calc(50% - 8px);
  left: -moz-calc(50% - 8px);
  left: calc(50% - 8px);
}
.language-menu-desktop:before {
  border: 16px solid transparent;
  border-bottom-color: #ddd;
}
.language-menu-desktop:after {
  margin-left: 1px;
  border: 15px solid transparent;
  border-bottom-color: #fff;
}

.language-font-white {
  color: $white;
}

@media only screen and (min-width: 700px) { /* desktop */
    .desktop-selector {
      height: 2.5rem;
      width: 8rem;
      z-index: 2;
    }
    .logo {
        max-height: 3rem;
    }
    .printer-name {
        font-size: 20px !important;
    }
}
@media only screen and (max-width: 700px) { /* mobile */

    .desktop-selector {
      height: 2.5rem;
      //width: 8rem;
      z-index: 2;
    }
    .logo {
        max-height: 2rem;
        max-width: 60%;
    }

    .printer-name {
        font-size: 13px !important;
    }

    .language-button {
        font-size: 14px;
    }

    .language-menu-desktop {
        font-size: 14px;
    }
}
.printer-name {
  color: $hp-black;
  font-size: 16pt;
}
.language-square {
  width: 2rem;
  height: 2rem;
  background-size: cover;
}
</style>

<style lang="scss">

.language-item {
  background-color: lightblue;
  font-family: Forma-Regular;
  z-index: 999;
}
.language-selected {
  background-color: $hp-blue;
  z-index: 999;
}

#logo-text{
  font-size: 16px;
}

</style>
