<template>
  <div
    class="content-center px-3 py-1 w-100 default-display"
    :class="{'d-flex-mobile': mobile,
             'd-flex-desktop': desktop}"
  >
    <div
      v-for="(item, index) in chapters"
      :key="'chapter_' + index"
      class="chapter-indicator-container py-3 mx-1"
      @click="changeChapter(index)"
    >
      <div
        class="chapter-indicator"
        :style="chapterStyle(index)"
      />
    </div>
  </div>
</template>
<script lang="ts">
export default {
    props: {
        chapters: {
            type: Array,
            default: new Array()
        },
        previousChapter: {
            type: [String, Number],
            default: 0
        },
        stepIndex: {
            type: Number,
            default: 0
        },
        mobile: {
            type: Boolean,
            default: false
        },
        desktop: {
            type: Boolean,
            default: false
        },
        chapter: {
            type: [String, Number],
            default: 0
        },
        step: {
            type: [String, Number],
            default: 0
        }
    },
    methods: {
        changeChapter(index) {
            let i = 0
            for (let c in this.chapters) {
                if (index == c) {
                    this.$emit('input', i)
                    return
                }
                for (let j = 0; j < this.chapters[c].steps.length; ++j) {
                    ++i
                }
            }
        },
        chapterStyle(chapter) {
            let delay = 0;
            if (this.previousChapter < chapter) { //going forward
                delay = (chapter - this.previousChapter) * 0.5;
            } else if (this.previousChapter > chapter) { //going backward
                delay = (this.previousChapter - chapter) * 0.5;
            }
            let percentage = Math.round(
                ((this.step) / this.chapters[this.chapter].steps.length) * 100
            );
            let halfStepSize = Math.round(
                ((1 / this.chapters[this.chapter].steps.length) * 100) / 2
            );
            if (this.chapter == chapter)
                return {
                    "background-position": 100 - percentage - halfStepSize + "% 0%",
                    "transition-delay": delay + "s"
                };
            else if (this.chapter > chapter)
                return {
                    "background-position": "0% 0%",
                    "transition-delay": delay + "s"
                };
            else
                return {
                    "background-position": "100% 0%",
                    "transition-delay": delay + "s"
                };
        }
    }
}
</script>
<style lang="scss" scoped>
.chapter-indicator-container {
    width: 100%;
    cursor: pointer;
}
.chapter-indicator {
    width: 100%;
    border-radius: 25px;
    background: $hp-gray;
    height: 0.3rem;
    background-size: 200% 100%;
    background-image: $hp-blue;
    background-image: -moz-linear-gradient(left, $hp-blue 50%, $hp-gray 50%);
    background-image: -webkit-linear-gradient(left, $hp-blue 50%, $hp-gray 50%);
    background-image: linear-gradient(to right, $hp-blue 50%, $hp-gray 50%);
    transition: background-position 0.5s ease-in-out;
}
@media only screen and (max-width: 991px) {
    .d-flex-mobile {
        display: -webkit-box !important;
        display: -ms-flexbox !important;
        display: flex !important;
    }
}
@media only screen and (min-width: 992px) {
    .d-flex-desktop {
        display: -webkit-box !important;
        display: -ms-flexbox !important;
        display: flex !important;
    }
}
.default-display {
    display: none;
}
</style>