<template>
  <div id="app">
    <router-view :key="$route.path"></router-view>
  </div>
</template>

<script lang="ts">
import "@/assets/styles/main.scss";
import { mapActions } from "vuex";

export default {
    name: "App",
    data() {
        return {
        }
    },
    computed: {},
    created() {
        this.$i18n.locale = this.$store.getters.languageLocale;
        window.addEventListener("load", this.init);
        this.readPrinter(this);
    },
    methods: {
        init() {
            console.log("App running");
        },
        ...mapActions(["readPrinter"])
    }
};
</script>

<style lang="scss">
#app {
    font-family: "Arial, sans-serif";
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
    height: 100%;
    border-top-style: solid;
    border-top-color: $hp-blue-button;
    border-top-width: 3px;
}
</style>
