<template>
  <slick
    ref="carousel"
    class="w-100"
    :options="slickOptions"
    @beforeChange="onIndexChange"
  >
    <div
      v-for="(s, index) in steps"
      :key="index"
      class="d-flex justify-content-center w-100 h-100 align-items-center"
    >
        <div class="d-flex flex-column bd-highlight mb-3">
            <div class="p-2 bd-highlight">
                <v-zoomer class="mw-100 mh-100 m-auto">
                 <img
                :src="require(`@/assets/img/${$store.state.printer}/${s.image}`)"
                style="object-fit: contain; width: 100%; height: 100%;"
                :alt="'step ' + s.index">
                </v-zoomer>
            </div>
            <div class="p-2 bd-highlight">
                <p class="px-4 step-text"><b>{{ s.important }}</b></p>
                <p class="px-4 step-text">{{ s.instructions }}</p>
                <font size="-1"><a :href="s.link" target= "_blank">{{ s.link }}</a></font>
                <p><font size="-1"><a :href="s.link" target= "_blank">{{ s.link2 }}</a></font></p>
                <p class="px-4 step-text">{{ s.instructions2 }}</p>
                <p class="px-4 step-text"><b>{{ s.notes }}</b></p>
                <p class="px-4 step-text">{{ s.instructions3 }}</p>
            </div>
        </div>
    </div>
  </slick>
</template>
<script lang="ts">
import Slick from 'vue-slick';
import 'slick-carousel/slick/slick.css';

export default {
    components: {
        Slick
        },
    props: {
        stepIndex: {
            type: Number,
            default: 0
        },
        steps: {
            type: Array,
            default: new Array()
        }
    },
    data() {
        return {
            slickOptions: {
                arrows: false,
                infinite: false
            }
        }
    },
    watch: {
        stepIndex() {
            this.step = this.stepIndex
            this.$refs.carousel.goTo(this.step)
        }
    },
    methods: {
        onIndexChange(event, slick, currentSlide, nextSlide) {
            this.$emit('input', nextSlide)
        }
    }
    
}

</script>
<style lang="scss">
.slick-slide, .slick-track, .slick-list, .slick-slide > div {
    height: 100%;
    width: 100%;
}

#printer-step-img{
    width: 90%;
    height: 90%;
}

@media (pointer: coarse) and (max-width: 370px) {
 #printer-step-img{
    width: 60%;
    height: 60%;
 }
 .btn{
    font-size: 12px !important;
  }
}

</style>