<template>
  <div id="main-div" class="d-flex flex-column flex-grow-1 w-100 h-100">
    <div class="d-flex flex-column justify-content-center flex-grow-1">
      <div class="mt-auto position-relative w-100 h-100 align-self-center printer" style=" top: -30px !important;">
        <img
          :src="require('@/assets/img/' + printer + '/' + textVariables.welcome.image )"
         class="background-image position-relative printer-image"
          style=""
          alt="Background"
        />
        <p class="printer-desc"> {{textVariables.welcome.printer_description   }}</p>
      </div>  
      <div>
        <Version @chosen="versionChosen()" v-if="'versions' in this.printer_data(this)"></Version>
        <!--<div class="mt-auto position-relative w-100 h-100 align-self-center">
          <img
            :src="require('@/assets/img/' + printer + '/' + textVariables.welcome.image )"
            class="background-image position-absolute printer-image"
            style="left: 50%; top: 50%; transform: translate(-50%,-50%);"
            alt="Background"
          />
        </div>-->
        <div v-else class="mt-auto position-relative next-view-instruction pb-4" style=" top: -30px !important;">
          <b-button
            variant="primary"
            style= "font-family: Forma-regular;"
            class="primary-button-hp continue-button"
            @click="nextScreen(true)"
          >{{ $t('continue') }}</b-button>
          <div class="mt-auto position-relative" style=" top: +10px !important;">
            <p><a href="https://www.hp.com/us-en/privacy/privacy-central.html" target="_blank">{{ $t('privacy') }}</a></p> 
          
            <div class="mt-auto position-relative" style=" top: -20px !important;">
              <p> <a href="https://www.hp.com/us-en/privacy/use-of-cookies.html" target="_blank">{{ $t('use of cookies') }}</a></p>
            </div>
          </div>
      </div>
      
        
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { debounce } from "../utils/debounce.js";
import { mapGetters } from "vuex";
import Version from "./Version.vue";

export default {
  components: {
    Version
  },
  data() {
    return {
      initialX: null,
      animate: false,
      moved: true,
      showWelcome: true,
      showText: false
    };
  },
  computed: {
    onMobile: function() {
      return window.matchMedia("(pointer: coarse)").matches;
    },
    textVariables: function() {
      return this.printer_data(this);
    },
    ...mapGetters(["printer", "printer_data"])
  },
  mounted() {
    document
      .getElementById("main-div")
      .addEventListener("touchstart", this.startTouch, { passive: true });
    document
      .getElementById("main-div")
      .addEventListener("touchmove", debounce(this.moveTouch, 200), {
        passive: true
      });
    setTimeout(() => {
      this.moved = false;
      setTimeout(() => {
        this.showWelcome = false;
      }, 1000);
    }, 1000);
  },
  methods: {
    startTouch: function(e) {
      this.initialX = e.touches[0].clientX;
    },
    moveTouch: function(e) {
      if (this.initialX === null) {
        return;
      }

      let currentX = e.touches[0].clientX;
      let diffX = this.initialX - currentX;
      if (diffX > 50) {
        this.animate = true;
        this.initialX = null;
      }
      if (e.cancelable) e.preventDefault();
    },
    nextScreen(force = false) {
      if (this.animate == true || force) this.$emit("next");
      else this.showText = true;
    },
    versionChosen(){
      this.$emit("chosen");
    }
  }
};
</script>

<style lang="scss" scoped>

.printer-desc{
    font-family: Forma-bold;
    color: #1c1c1c;
    font-size: 2.375rem;
}

@media not all and (pointer: coarse),
  (min-width: 992px),
  (orientation: landscape) {
  /* desktop */
  .sliding-image {
    max-width: 75%;
    max-height: 50vh;
    margin: auto;
    transition: all 1s ease-in;
    z-index: 2;
  }

  .printer-image {
    height: 500px;
  }

}

@media (pointer: coarse) and (max-width: 370px) {
  //movil 4 pulgadas
  .printer-image {
      width: 80% !important;
      height: 80% !important;
    }
  .printer-desc{
    font-size: 1.3rem !important;
  }
}

@media (max-width: 1024px) and (orientation: landscape){
  .printer-image{
    height: 500px;
  }

}

@media (max-width: 800px) and (orientation: portrait){
  .printer-image{
    height: 500px;
  }

}

@media (min-width: 1024px) and (orientation: portrait){
  .printer-image{
    height: 700px;
  }
  .printer{
    top:20%;
  }
}


@media (max-width: 1024px) and (orientation: landscape){
  .printer-image{
    height: 500px;
  }
}

@media (pointer: coarse) and (max-width: 660px) and (orientation: portrait) {
  /* mobile */
  .sliding-image {
    width: 160%;
    max-height: 50vh;
    transition: all 1s ease-in;
    z-index: 2;
    width: auto;
    height: auto;
  }

  .printer-image {
    width: 100%;
    height: auto;
  }

  .printer-desc{
    font-size: 1.5rem;
  }

  .btn {
    font-size: 14px !important;
    min-width: 300px;
  min-height: 50px;
  }
}

@media (max-width: 991px) and (orientation: landscape) {
  /* mobile */
  .welcome-div {
    display: none !important;
  }
}

.slide-icon {
  margin: 1rem 0.1rem;
  width: 2rem;
  border-radius: 50%;
  background: $hp-blue;
  height: 2rem;
  color: white;
}

.slide-to-unlock-text {
  color: $hp-blue;
  font-weight: bold;
  font-size: 12pt !important;
}

.next-view-instruction {
  bottom: 0;
  transition: opacity 1s ease-in;
}
.welcome-text {
  color: $hp-blue;
  font-size: 12vw;
  font-weight: bold;
}
.description-text {
  color: black;
  text-align: left;
}
.continue-button {
  background-color: $hp-blue;
  border-color: $hp-blue;
  min-width: 300px;
  min-height: 50px !important;
}

.primary-button-hp {
  background-color: $hp-blue !important;
  border-color: $hp-blue;
  min-width: 300px;
  min-height: 50px;
}

.primary-button-hp:hover {
  background-color: $hp-blue-hover;
  border-color: $hp-blue;
}

.primary-button-hp:active {
  background-color: $hp-blue-hover !important;
}

.primary-button-hp:focus{
  background-color: $hp-blue-hover !important;
} 

</style>
<style lang="scss">
.printer-animation-slide {
  transform: translateX(-110%);
}
.printer-animation-image-moved {
  transform: translateX(100%);
}
.printer-animation-fade-enter-active,
.printer-animation-fade-leave-active {
  transition: opacity 0.5s;
}
.printer-animation-fade-enter, .printer-animation-fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
