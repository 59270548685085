<template>
  <div class="d-flex flex-column justify-content-center align-items-center h-80 w-80 pb-5" >
    <p class="stand-select-text">
        {{$t('stand-select')}}
    </p>
    <div>
      <b-button
        variant="primary"
        v-for="version in textVariables.versions"
        :key="version"
        @click="chooseVersion(version)"
        class="version-button my-2 mx-2 w-40"
      >
        {{$t(version)}}
      </b-button>
    </div>
  </div>
</template>

<script lang="ts">
import { mapGetters, mapMutations, mapActions } from "vuex";

export default {
  name: 'Version',
  mounted() {
      this.setDefaultVersion(this)
  },
  computed: {
    textVariables: function() {
      return this.printer_data(this);
    },
    ...mapGetters(["printer", "version", "printer_data"])
  },
  methods: {
    ...mapMutations(["updateVersion"]),
    ...mapActions(["setDefaultVersion"]),
    chooseVersion(version) {
      this.updateVersion(version);
      this.$emit("chosen");
    }
  }
};
</script>

<style lang="scss" scoped>
.version-button {
  background-color: $hp-blue !important;
  border-color: $hp-blue !important;
}

.stand-select-text {  
    font-family: Forma-regular;
    font-size: 1.125rem;
}


@media (min-width: 660px) and (orientation: portrait) {

  .stand-select-text{
    font-size: 1.5rem !important;
  }

}

@media (min-width: 1024px) and (orientation: landscape) {

  .stand-select-text{
    margin-bottom: 0 !important;
  }

}

</style>