var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex w-100 p-3 align-items-center"},[_c('img',{staticClass:"mw-50 pl-3 logo",attrs:{"src":require('@/assets/img/HPlogo-light-XL.svg'),"alt":"HP logo"}}),_c('div',[_c('p',{staticClass:"mb-0 ml-2 printer-name",attrs:{"id":"logo-text"}},[_vm._v(_vm._s(_vm.textsVariables.name))])]),_c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.onClickOutside),expression:"onClickOutside"}],staticClass:"desktop-selector ml-auto"},[_c('button',{staticClass:"language-button position-relative",on:{"click":function($event){_vm.showLanguagePanel = !_vm.showLanguagePanel}}},[_vm._v(" "+_vm._s(_vm.$i18n.messages[_vm.$i18n.locale].language_name)+" "),_c('img',{directives:[{name:"show",rawName:"v-show",value:(!_vm.showLanguagePanel),expression:"!showLanguagePanel"}],attrs:{"src":require('@/assets/img/arrowdown.svg')}}),_c('img',{directives:[{name:"show",rawName:"v-show",value:(_vm.showLanguagePanel),expression:"showLanguagePanel"}],attrs:{"src":require('@/assets/img/arrowup.svg')}})]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showLanguagePanel),expression:"showLanguagePanel"}],staticClass:"position-relative bg-white language-menu-desktop mr-2 hide-mobile border"},[_c('ul',{staticClass:"my-2 p-0"},_vm._l((_vm.$i18n.availableLocales),function(language,index){return _c('li',{key:'language_' + index,staticClass:"d-flex px-2 py-1 align-items-center justify-content-center",class:{
            'language-selected': _vm.$i18n.locale == language,
            'language-item': index == _vm.hoveredItem && _vm.$i18n.locale != language,
            'language-font-white': _vm.$i18n.locale == language
          },on:{"click":function($event){return _vm.changeLocale(language)},"mouseover":function($event){_vm.hoveredItem = index},"mouseout":function($event){_vm.hoveredItem = null}}},[_vm._v(" "+_vm._s(_vm.$i18n.messages[language].language_name)+" ")])}),0)])]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showLanguagePanel),expression:"showLanguagePanel"}],staticClass:"position-absolute bg-white h-100 w-100 language-menu hide-desktop"},[_c('div',{staticClass:"d-flex flex-row-reverse w-100 p-3 border-bottom"},[_c('div',{staticClass:"language-square",on:{"click":function($event){_vm.showLanguagePanel = !_vm.showLanguagePanel}}},[_vm._v(" ✕ ")])]),_c('ul',{staticClass:"my-2 p-0"},_vm._l((_vm.$i18n.availableLocales),function(language,index){return _c('li',{key:'language_' + index,staticClass:"d-flex px-2 py-2 align-items-center justify-content-center",class:{
            'language-selected': _vm.$i18n.locale == language,
            'language-item': index == _vm.hoveredItem && _vm.$i18n.locale != language,
            'language-font-white': _vm.$i18n.locale == language
          },staticStyle:{"font-family":"Forma-Regular"},on:{"click":function($event){return _vm.changeLocale(language)},"mouseover":function($event){_vm.hoveredItem = index},"mouseout":function($event){_vm.hoveredItem = null}}},[_vm._v(" "+_vm._s(_vm.$i18n.messages[language].language_name)+" ")])}),0)])])
}
var staticRenderFns = []

export { render, staticRenderFns }