<template>
  <div
      id="stepsContainer"
      class="d-flex h-100 justify-content-center"
    >
      <div class="d-flex h-100 align-items-center mr-3">
        <button
          class="arrow-button hide-mobile"
          :style="arrowStyleLeft()"
          :disabled="stepIndex == 0"
          @click="--stepIndex"
          />
      </div>
      <div
        id="carouselContainer"
        class="d-flex flex-column align-items-center mh-100 mw-100"
      >
        <h1 id="chapter-title" class="mt-4 w-100">
          {{ chapters[chapter].chapter }}
        </h1>
        <ChapterIndicator
          id="chapter-indicator-mobile"
          :chapters="chapters"
          :mobile="true"
          :desktop="true"
          :previous-chapter="previousChapter"
          :step-index="stepIndex"
          :step="step"
          :chapter="chapter"
          @input="stepIndex = $event"
        />
        <div class="w-100 px-2">
            <CarouselView
            id="carousel-component"
            :step-index="stepIndex"
            :steps="steps"
            @input="stepIndex = $event"
          />
        </div>
        <div
          id="description-text"
          class="step-description mt-auto"
        >        
          <div
            class="w-100"
          >
            <transition
              name="fade"
              mode="out-in"
            >
              <div
                v-if="isLastStep"
                class="d-flex justify-content-center w-100 steps-bottom-indicator"
              >
                <div class="w-100">
                  <b-button
                    variant="primary"
                    to="/survey"
                    class="primary-button-hp steps-done-button"
                  >
                    {{ $t('done') }}
                  </b-button>
                </div>
              </div>
              
              <PageCounter
                v-else
                class="page-counter"
                :current-page="step" 
                :total-page="chapters[chapter].steps.length"
              />
            </transition>
          </div>
        </div>
      </div>
      <div class="d-flex h-100 align-items-center ml-3">
        <button
          class="arrow-button hide-mobile"
          :style="arrowStyleRight()"
          :disabled="isLastStep"
          @click="stepIndex++"
          />
      </div>
    </div>
</template>

<script lang="ts">
import { mapGetters } from "vuex";
import { debounce } from "../utils/debounce.js";
import CarouselView from "./views/CarouselView.vue";
import ChapterIndicator from "./views/ChapterIndicator.vue";
import PageCounter from './views/StepCounter.vue';

export default {
    components: {
        CarouselView,
        ChapterIndicator,
        PageCounter
    },
    data() {
        return {
            previousChapter: 0,
            chapter: 0,
            step: 0,
            stepIndex: 0,
        };
    },
    computed: { 
        steps() {
            let result = []
            if (this.chapters != undefined)
                for (let c in this.chapters) {
                    result = result.concat(this.chapters[c].steps);
                }
            return result
        },
        chapters() {
            return this.printer_data(this).chapters.filter(chapter => 
                !("version" in chapter) || this.version == chapter.version).map(function(chapter) {
                    chapter.steps = chapter.steps.filter(step => !("version" in step) || this.version == step.version);
                    return chapter;
                }, this);
        },
        ...mapGetters(["printer", "version", "printer_data"]),
        isLastStep() {
            if (this.steps.length - 1 == this.stepIndex) return true;
            return false;
        }
    },
    watch: {
        step: function() {
            this.setDivHeight()
        },
        chapter: function() {
            this.setDivHeight()
        },
        stepIndex: function(newStep) {
            let index = 0
            for (let c in this.chapters) {
                for (let s in this.chapters[c].steps) {
                    if (index == newStep) {
                        this.previousChapter = this.chapter
                        this.step = s
                        this.chapter = c
                        return
                    }
                    ++index
                }
            }
            return
        }
    },
    mounted() {
        this.setDivHeight()
        window.addEventListener('resize', debounce(this.setDivHeight, 200));
    },
    methods: {
         arrowStyleRight(){
            let arrowRight = "Right_arrow_button.svg";
            if(this.isLastStep)
                arrowRight ="right_arrow_gray.svg";
            return {backgroundImage: 'url(' + require('@/assets/img/' + arrowRight ) + ')'}
        },
        arrowStyleLeft(){
            let arrowLeft = "Left_arrow_button.svg";
            if(this.stepIndex == 0)
                arrowLeft ="left_arrow_gray.svg";
            return {backgroundImage: 'url(' + require('@/assets/img/' + arrowLeft ) + ')'}
        },
        setDivHeight() {
            this.$nextTick(function() {
                if (document.getElementById('carouselContainer') != null) {
                    if (window.innerWidth > window.innerHeight/2 && window.innerWidth > 991)
                        document.getElementById('carouselContainer').style.width = window.innerHeight/2 + "px"
                    else
                        document.getElementById('carouselContainer').style.width = ""
                }
                if (document.getElementById('description-text') != null && document.getElementById('chapter-indicator-mobile') != null) {
                    let height = window.innerHeight - document.getElementById('description-text').getBoundingClientRect().height - (document.getElementById('chapter-indicator-mobile').getBoundingClientRect().top + document.getElementById('chapter-indicator-mobile').getBoundingClientRect().height)
                    document.getElementById('carousel-component').style.height = height + "px"
                }
                
            })
        },
        goToStep: function(stepNumInChapter) {
            let index = 0
            for (let c in this.chapters) {
                for (let s in this.chapters[c].steps) {
                    if (this.chapter == c && stepNumInChapter == s) {
                        this.stepIndex = index
                        return
                    }
                    ++index
                }
            }
            return
        }
    }
};
</script>

<style lang="scss" scoped>
@media (pointer: coarse) and (max-width: 370px) {

  #chapter-indicator-mobile{
    width: 80% !important;
  }
 .step-text {
    text-align: center;
    font-size: 12px !important;
}
  #chapter-title{
    font-size: 16px !important;
  }

  .btn{
    font-size: 12px !important;
    min-width: 300px;
  min-height: 50px;
  }
}

#stepsContainer {
    max-width: 1024px;
    margin-left: auto;
    margin-right: auto;
    overflow: hidden;
}

@media only screen and (min-width: 992px) {
    .flex-desktop {
        display: none;
    }
}

@media only screen and (min-width: 991px) {
    .flex-desktop {
        display: flex;
    }
}

#chapter-title{
  font-size: 18px;
}

.navigation-dots-mobile {
    position: absolute;
    bottom: 0;
}

.page-counter {
    height: 6vh;
    font-family: Forma-Bold, Arial; 
    font-size: 16px; 
    color: #333;
}

.step-text {
   text-align: center;
    font-size: 15px;
}

.steps-done-button {
  width: 100%;
  background-color: $hp-blue !important;
  border-color: $hp-blue !important;
  min-width: 300px;
  min-height: 50px;
  line-height:3;
}

.steps-done-button:active {
  background-color: $hp-blue-hover !important;
  border-color: $hp-blue !important;
}

.steps-done-button:hover {
  background-color: $hp-blue-hover !important;
  border-color: $hp-blue !important;

}

.arrow-button {
  border: none;
  z-index: 3;
  background-color: transparent;
  background-size: 100%;
  width: 2.5rem;
  height: 2.5rem;
  outline: none;
}

@media only screen and (max-width: 991px) { /* mobile */
  .btn {
    font-size: 14px !important;
    min-width: 300px;
  min-height: 50px;
  }
}

</style>
