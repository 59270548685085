<template>
  <div class="page-counter">
    {{ computedCurrentPage }}  / {{ totalPage }}
  </div>
</template>

<script>
export default {
  props: {
    currentPage: {
      type: Number,
      required: true,
    },
    totalPage: {
      type: Number,
      required: true,
    },
  },
  computed: {
    computedCurrentPage() {
      return parseInt(this.currentPage) + 1;
    },
  },
};
</script>

<style lang="scss" scoped>
.page-counter {
  
  font-family: Forma-Bold, Arial; 
  font-size: 16px; 
  color: #333;
  font-weight: bold ;
}
</style>