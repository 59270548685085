import en from "./locales/en.json";
import fr from "./locales/fr.json";
import de from "./locales/de.json";
import ko from "./locales/ko.json";
/*import nl from "./locales/nl.json";*/
import pl from "./locales/pl.json";
import pt from "./locales/pt.json";
/*import ru from "./locales/ru.json";*/
import zhcn from "./locales/zhcn.json";
import zhtw from "./locales/zhtw.json";
import ja from "./locales/ja.json";
/*import ca from "./locales/ca.json";*/
import es from "./locales/es.json";
import it from "./locales/it.json";

export default {
    en,
    fr,
    de,
    ja,
    ko,
    /*nl,*/
    pl,
    pt,
    /*ru,*/
    zhcn,
    zhtw,
    /*ca,*/
    es,
    it
};
