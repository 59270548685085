import axios from "axios";
import Configuration from "../utils/configuration";


export default {
  methods: {
    handleError(error){
      // Error 
      if (error.response) {
          /*
          * The request was made and the server responded with a
          * status code that falls out of the range of 2xx
          */
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
      } else if (error.request) {
          /*
          * The request was made but no response was received, `error.request`
          * is an instance of XMLHttpRequest in the browser and an instance
          * of http.ClientRequest in Node.js
          */
          console.log(error.request);
      } else {
          // Something happened in setting up the request and triggered an Error
          console.log('Error', error.message);
      }
      console.log(error);
      return error;
    },
    getEndpointAsync: async function(
      endpoint,
      rootRoute = Configuration.value("APIM_ENDPOINT")
    ) {
      try{
        const response = await axios
        .get(rootRoute + endpoint);
        // Success
        console.log(response);
        return response.data;

      } catch(error){
        this.handleError(error);
      }
    },
    postEndpointAsync: async function(endpoint, data) {
      try{
        const response = await axios
        .post(Configuration.value("APIM_ENDPOINT") + endpoint, data, {
          headers: {
            "Content-Type": "application/json"
            ,"XApiKey":Configuration.value("API_KEY")
          }
        });
        // Success
        console.log(response);
        return response.data;

      } catch(error){
        this.handleError(error);
        throw error;
      }
    },
    postEndPointWithoutDataAsync: async function(endpoint) {
      try{
        const response = await axios
        .post(Configuration.value("APIM_ENDPOINT") + endpoint, {
          headers: {
            "content-type": "application/json"
            ,"XApiKey":Configuration.value("API_KEY")
          }
        });
        // Success
        console.log(response);
        return response.data;

      } catch(error){
        this.handleError(error);
      }
    },
    postFormDataEndpointAsync: async function(endpoint, formData) {
      try{
        const response = await axios
        .post(Configuration.value("APIM_ENDPOINT") + endpoint, formData, {
          headers: {
            "Content-Type": "multipart/from-data"
            ,"XApiKey":Configuration.value("API_KEY")
          }
        });
        // Success
        console.log(response);
        return response.data;

      } catch(error){
        this.handleError(error);
      }
    },
    putEndpointAsync: async function(
      endpoint,
      data,
      rootRoute= Configuration.value("APIM_ENDPOINT")
    ) {
      try{
        const response = await axios
        .put(rootRoute + endpoint, data, {
          headers: {
            "Content-Type": "application/json"
            ,"XApiKey":Configuration.value("API_KEY")
          }
        });
        // Success
        console.log(response);
        return response.data;

      } catch(error){
        this.handleError(error);
      }
    },
    deleteEndpointAsync: async function(endpoint) {
      try{
        const response = await axios
        .delete(Configuration.value("APIM_ENDPOINT") + endpoint, {
          headers: {
            "Content-Type": "application/json"
            ,"XApiKey":Configuration.value("API_KEY")
          }
        });
        // Success
        console.log(response);
        return response.data;

      } catch(error){
        this.handleError(error);
      }
    }
  }
};
